<template>
	<v-container>
	  <v-row>
	    <v-col cols="12">
	      <v-card>
	        <v-toolbar dark dense color="secondary">
	          Seguimiento a Pedidos
	          
          <v-spacer></v-spacer>
            <v-text-field
              dark
              v-model="search"
              append-icon="search"
              label="Buscar "
              single-line
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
	          <v-btn
              small
              class="mx-2"
              color="success"
              @click="consultar"
            >
              Consultar
            </v-btn>
            
	          <!-- <v-btn
	            class="ma-2"
	            small dark color="warning"
	            @click="exportar()">
	            <v-icon>save_alt</v-icon>
	          </v-btn> -->

	        </v-toolbar>

	        <v-card-text>
	          <v-row>

	            <!-- FECHA INICIAL -->
	            <!-- <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
	              <v-menu
	                v-model="menu1"
	                :close-on-content-click="false"
	                :nudge-right="40"
	                transition="scale-transition"
	                offset-y
	                min-width="290px"
	              >
	                <template v-slot:activator="{ on }">
	                  <v-text-field
	                    v-model="date1"
	                    label="Fecha Inicial"
	                    prepend-icon="event"
	                    readonly
	                    v-on="on"
	                  ></v-text-field>
	                </template>
	                <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
	              </v-menu>
	            </v-col> -->

	            <!-- FECHA FINAL -->
	        <!--     <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
	              <v-menu
	                v-model="menu2"
	                :close-on-content-click="false"
	                :nudge-right="40"
	                transition="scale-transition"
	                offset-y
	                min-width="290px"
	              >
	                <template v-slot:activator="{ on }">
	                  <v-text-field
	                    v-model="date2"
	                    label="Fecha Final"
	                    prepend-icon="event"
	                    readonly
	                    v-on="on"
	                  ></v-text-field>
	                </template>
	                <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
	              </v-menu>
	            </v-col> -->

	            <!-- TIPODOC  -->
	            <!-- <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
	              <v-select
	                :items="tipodocs"
	                v-model="tipodoc"
	                label="Tipo documento"
	              ></v-select>
	            </v-col> -->

	            <!-- BUSCAR -->
	            
	            <!-- DATATABLE -->
	            <v-col cols="12">
	              <v-data-table
	                :loading="loading"
	                loading-text="Cargando..."
	                :headers="headers"
	                :items="Docum"
	                :items-per-page="longitud"
	                class="elevation-1"
	                fixed-header
	                height="450"
	                :search="search"
	                hide-default-footer
	                dense
	              >
	                <template v-slot:item="{ item }" >
	                  <tr v-on:dblclick="edit(item)">
	                    <td>{{ item.numdoc}}</td>
	                    <!-- <td>{{ item.Tipodoc}}</td> -->
	                    <td class="text-end">{{ item.fecha}}</td>
	                    <!-- <td>{{ item.importe}}</td> -->
	                    <!-- <td>{{ item.descuento}}</td> -->
	                    <!-- <td>{{ item.impuesto1}}</td> -->
	                    <td class="text-end">{{ item.total}}  {{ item.divisa}}</td>
	                    <td :style="{color: item.color }">{{ item.EstatusPedido}}</td>
	                   	<td class="text-end">{{ item.FechaStatus}} {{ item.HoraStatus}} </td>
	                   	<td>{{ item.mensajeria}} {{ item.guia}} </td>
	                    <!-- <td></td> -->
	                    <!-- ]<td>{{ item.guialink}}</td> -->

	                   	<!-- IMAGENES DE PAGOS -->
	                    <td><img :src="item.fotopago1"
                				style="max-height: 70px; width: 120"></td>

											<td><img :src="item.fotopago2"
                				style="max-height: 70px; width: 120"></td>

              				<!--
              				<td> <v-img src="item.fotopago1" contain max-height="80"></v-img></td> -->
              				<!-- <td v-else><img :src="logo"  style="max-height: 50px"></td> -->

	                    <!-- <td v-if="item.status == 0" :style="{color: getColor(item.status) }">Abierta</td>
	                    <td v-if="item.status == 9" :style="{color: getColor(item.status) }">Pedida</td> -->
	                  </tr>
	               </template>
	              </v-data-table>

	            </v-col>
	          </v-row>
	        </v-card-text>
	      </v-card>

	    </v-col>
  	</v-row>
	</v-container>
</template>

<script>
 	// import axios from 'axios'

import axios from 'axios'
// // axios.defaults.baseURL = 'http://localhost:1010/'
import { mapGetters, mapActions } from 'vuex'
import excelMixin from '@/mixins/excelMixin.js'
var accounting = require('accounting')
var base64 = require('base-64')

export default {
  mixins: [excelMixin],

  data () {
    return {
      // FECHAS
      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),

      menu1: false,
      menu2: false,

      // SELECTOR TIPODOC
      tipodocs: ['Pedido', 'Cotización'],
      tipodoc: 'Pedido',
      e1: 'Pedidos',
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      longitud: 5,

      	numcli: '',
      	numalm: '',
      headers: [
        { text: 'ID', align: 'start', value: 'numdoc' },
        { text: 'Fecha', align: 'start', value: 'fecha' },
        { text: 'Total', value: 'total', align: 'end' },
        { text: 'Estatus', value: 'EstatusPedido' },
        { text: 'Fecha y hora Estatus', align: 'start', value: 'FechaStatus' },
        { text: 'Mensajería', align: 'start', value: 'mensajeria' },
        { text: 'Pago Confirmar', align: 'start', value: 'Numdoc' },
        { text: 'Pago Pedido', align: 'start', value: 'Numdoc' },
        // { text: 'Divisa', value: 'divisa' },
        // { text: 'Hora Estatus', align: 'start', value: 'HoraStatus' },
        // { text: 'Importe',   align: 'rigth',  value: 'importe'   },
        // { text: 'Descuento', value: 'descuento' , align: 'rigth'},
        // { text: 'Impuesto',  value: 'impuesto1' , align: 'rigth'},
        // { text: 'Guía', align: 'start', value: 'guia' },
        // { text: 'Tipo',  align: 'end'  ,           value: 'Tipodoc' },
        // { text: 'tc',    value: 'Tc' ,},
      ],

      Docum: []
    }
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getModo', 'getInfoVend'])
  },

  created () {
    // Recibir parametro de tipo de Documento a Abrir.
    // console.log("Params",this.$route.params)

    this.tipodocumento = this.$route.params.tipodocumento
    this.numcli = this.getdatosUsuario.numcli

    // Definir valores iniciales
    // Fecha inicial Primero de mes.
    const date0 = new Date()
    const mes = date0.getMonth() - 1
    const anio = date0.getFullYear()

    // console.log(mes, anio)
    this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
    this.consultar()
  },

  methods: {
    ...mapActions('documento', ['addDocumento']),

    // Consulto todos los clientes
    consultar () {
      	// API AXIOS api/v2
      // var url = "http://localhost:8094/api/v2/"

      var url = process.env.VUE_APP_OPTIONS_ROOT.replace('/tienda3', '/api/v2/')
      var uri = url + 'seg.conseguim.filtros'
      // console.log("url completa", uri)

      this.Docum = []
      // console.log(this.tipodoc)
      const payload = {
        Numvend: '',
        Numcli: this.numcli,
        Numalm: '',
        Fecha1: this.date1,
        Fecha2: this.date2,
        Tipodoc: this.tipodoc
      }

      if (this.tipodoc == 'Pedido') {
        payload.Tipodoc = ' P'
      } else {
        payload.Tipodoc = ' Q'
      }

      this.loading = true

      const token = localStorage.tlkey
      const cUrlWeb = process.env.VUE_APP_OPTIONS_ROOT
		    // console.log( cUrlWeb)
      // console.log("payload", payload)
      // console.log("token tlkey", token)
      axios.post(uri, payload, { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${token}` } }).then(response => {
        // console.log(response.data)

        // Calcular el Total.
        if (response.data.result.length > 0) {
          // console.log(response.data)
          this.Docum = response.data.result
          // console.log(this.docum)
          this.Docum.forEach(element => {
            //DIVISA
            if (element.divisa == 'P') {
              element.divisa = 'MXN'
            } else {
              element.divisa = 'USD'
            }

            element.importe = accounting.unformat(element.importe)
            element.impuesto1 = accounting.unformat(element.impuesto1)
            element.descuento = accounting.unformat(element.descuento)
            element.total = element.importe - element.descuento + element.impuesto1

            element.importe = accounting.formatNumber(element.importe, 2)
            element.impuesto1 = accounting.formatNumber(element.impuesto1, 2)
            element.descuento = accounting.formatNumber(element.descuento, 2)
            element.total = accounting.formatNumber(element.total, 2)

            // PONER RUTA COMPLETA A LA FOTO.
            // VUE_APP_OPTIONS_ROOT=https://saitmd3.com/tienda3
		          const cUrlWeb = process.env.VUE_APP_OPTIONS_ROOT
		          // console.log( cUrlWeb)
		          // let cUrlWeb ="https://saiterp.com/cosmeticoszalie/tienda3/"
		          const urlpago1 = cUrlWeb.replace('tienda3', 'fotossait/')

		          if (element.fotopago1 != '') {
		        	  element.fotopago1 = urlpago1 + element.fotopago1
		          }

		          if (element.fotopago2 != '') {
		        	  element.fotopago2 = urlpago1 + element.fotopago2
		          }

		          // console.log(urlpago1)

            // INTERPRETAR EL ESTATUS
            switch (element.statusped) {
            case '0':
              // console.log("Recibido", element.statusped)
              element.EstatusPedido = 'Recibido'
              element.color = 'red'
              element.FechaStatus = element.fecha
              break

            case '1':
              element.EstatusPedido = 'Confirmado'
              element.color = 'blue'
              element.FechaStatus = element.fecha
							  break

            case '2':
              element.EstatusPedido = 'En Proceso'
              element.color = 'green'
              element.FechaStatus = element.fsurtir
              element.HoraStatus = element.hsurtir
              break

            case '3':
              element.EstatusPedido = 'Terminado'
              element.color = 'orange'
              element.FechaStatus = element.fterminar
              element.HoraStatus = element.hterminar
              break

            case '4':
              element.EstatusPedido = 'Autorizado'
              element.color = 'indigo'
							  break

            case '5':
              element.EstatusPedido = 'Enviado'
              element.color = 'teal'
              element.FechaStatus = element.fenviar
              element.HoraStatus = element.henviar

							    break
            case '6':
              element.EstatusPedido = 'Entregado'
							    break

							  // default:
							  //   element.EstatusPedido = "Recibido"
							    // Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
							    // break
            }
          })
          this.longitud = this.Docum.length
          // console.log("EstatusPedido", this.Docum)
        }
      }).catch(function (error) {
        console.log(error)
      }).finally(() => this.loading = false)
    },

    edit (tipodoc) {
      console.log("tipodoc", tipodoc)
      this.addDocumento(tipodoc)
      var encodedData = base64.encode(tipodoc.Iddocum)
      var doc = { doc: encodedData }
      // this.$router.push({name:'documento', params: {info: doc, origen: 'cotizacion'}}).catch(err => {console.log(err)})

      this.$router.push({ name: 'operaped', params: { info: tipodoc, origen: 'cotizacion' } }).catch(err => { console.log(err) })
    },

    getColor (status) {
      if (status == 0) return 'red'
      else if (status == 9) return 'green'
      else return 'green'
    },

    exportar () {
      const xls = {
        data: this.Docum,
        tHeaders: ['ID doc', 'Nombre', 'Tipo', 'Fecha', 'Total', 'Divisa', 'Estatus'],
        filterVal: ['Numdoc', 'nomcli', 'Tipodoc', 'Fecha', 'importe', 'divisa', 'Status'],
        nomfile: 'Consulta de Documentos'
      }
      // console.log(xls)
      this.exportarXls(xls)
    }

  }
}
</script>
